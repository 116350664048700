<template>
  <header class="header">
    <div class="container">
      <div class="header__inner">
        <div class="header__logo">
          <router-link to="/">
            <img
              class="img-fluid"
              src="@/assets/images/Logo.svg"
              alt="TooBizi Logo"
            />
          </router-link>
        </div>
        <div v-if="isUserAuthenticated" class="header__menu">
          <Button
            theme="link"
            className="mr-5"
            :actionHandler="() => this[actions.USER_LOGOUT]()"
          >
            Odhlášení
          </Button>
          <Button
            v-if="withAfterLoginButton"
            theme="primary"
            isActive
            :actionHandler="() => this.$router.push('/order-overview')"
            className="d-none d-sm-none d-md-inline-block"
          >
            Vstoupit
          </Button>
          <Button
            v-if="withAfterLoginButton"
            theme="link"
            :actionHandler="() => redirectToDesiredView()"
            className="d-inline-block d-sm-inline-block d-md-none"
          >
            Vstoupit
          </Button>
        </div>
        <div
          v-else
          class="header__menu"
          :class="mobileMenuOpen && 'header__menu--active'"
        >
          <nav>
            <ul>
              <li>
                <a
                  v-if="isHomepage()"
                  href="/#jak-to-funguje"
                  @click="this.smoothScroll"
                  >Jak to funguje</a
                >
                <router-link v-else to="/#jak-to-funguje"
                  >Jak to funguje</router-link
                >
              </li>

              <li>
                <a v-if="isHomepage()" href="/#cenik" @click="this.smoothScroll"
                  >Ceník</a
                >
                <router-link v-else to="/#cenik">Ceník</router-link>
              </li>

              <li>
                <a
                  v-if="isHomepage()"
                  href="/#recenze"
                  @click="this.smoothScroll"
                  >Recenze</a
                >
                <router-link v-else to="/#recenze">Recenze</router-link>
              </li>

              <li>
                <a v-if="isHomepage()" href="/#demo" @click="this.smoothScroll"
                  >Demo</a
                >
                <router-link v-else to="/#demo">Demo</router-link>
              </li>

              <li>
                <Button
                  theme="link"
                  className="mr-3"
                  v-on:click="this.openMobileMenu"
                  :actionHandler="() => this.$router.push('/login')"
                  >Přihlášení
                </Button>
              </li>
            </ul>
          </nav>

          <Button
            theme="primary"
            isActive
            v-on:click="openMobileMenu;"
            :actionHandler="
              () => {
                this.gtmTrack();
                this.$router.push('/login');
              }
            "
            className="d-md-inline-block"
          >
            Koupit
          </Button>

          <Button
            v-if="isUserAuthenticated && withAfterLoginButton"
            theme="primary"
            isActive
            :actionHandler="() => $router.push('/order-overview')"
            className="d-none d-sm-none d-md-inline-block"
          >
            Vstoupit
          </Button>
          <Button
            v-if="isUserAuthenticated && withAfterLoginButton"
            theme="link"
            :actionHandler="() => redirectToDesiredView()"
            className="d-inline-block d-sm-inline-block d-md-none"
          >
            Vstoupit
          </Button>
        </div>
        <button
          class="header_mobile hamburger hamburger--spin"
          :class="mobileMenuOpen && 'is--active'"
          v-on:click="openMobileMenu"
          type="button"
          aria-label="Menu"
          aria-controls="navigation"
        >
          <span class="hamburger-box">
            <span class="hamburger-inner"></span>
          </span>
        </button>
      </div>
    </div>
  </header>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Button from "@/components/Button";
import { USER_MODULE } from "@/constants/modules";
import { USER_LOGOUT } from "@/constants/actions";

import "./Header.scss";

export default {
  data: () => ({
    USER_LOGOUT,
    mobileMenuOpen: false,
    actions: { USER_LOGOUT }
  }),
  name: "Header",
  components: {
    Button
  },
  props: {
    withAfterLoginButton: Boolean
  },
  computed: {
    ...mapGetters(USER_MODULE, ["isUserAuthenticated"])
  },
  methods: {
    ...mapActions(USER_MODULE, [USER_LOGOUT]),
    openMobileMenu() {
      var body = document.querySelector("body");
      this.mobileMenuOpen = !this.mobileMenuOpen;
      if (this.mobileMenuOpen) {
        body.classList.add("disable--scroll");
      } else {
        body.classList.remove("disable--scroll");
      }
    },
    gtmTrack: function() {
      if (this.$gtm.enabled()) {
        try {
          window.dataLayer?.push({
            event: "buyClicked"
            // further parameters
          });
        } catch (e) {
          return;
        }
      }
    },
    smoothScroll: function(event) {
      event.preventDefault();
      this.openMobileMenu();
      let target = event.currentTarget.href.split("#")[1];
      // element which needs to be scrolled to
      var element = document.querySelector("#" + target);
      // scroll to element
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest"
      });
    },
    isHomepage() {
      return this.$router.history.current.path === "/";
    }
  }
};
</script>
